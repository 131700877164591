import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResourceGridElement from '../ResourceGridElement';

import './index.css';

export class ResourcesGrid extends Component {
  static propTypes = {
    resources: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { resources, title } = this.props;

    const resourceNodes = resources.map((resource, resourceIndex) => {
      return (
        <ResourceGridElement
          key={`${resource.path}-${resourceIndex}`}
          path={resource.path}
          title={resource.title}
          date={resource.date}
          author={resource.author}
          authorTitle={resource.authorTitle}
          authorCompany={resource.authorCompany}
          videoLength={resource.videoLength}
          thumbnailImage={resource.thumbnailImage}
        />
      );
    });

    return (
      <div className="component resources-grid">
        <div className="resources-grid-header">{title}</div>
        <div className="resources-grid-body">{resourceNodes}</div>
      </div>
    );
  }
}

export default ResourcesGrid;
