import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class ResourceByline extends Component {
  static propTypes = {
    author: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorCompany: PropTypes.string.isRequired,
  };

  render() {
    const { author, authorTitle } = this.props;

    return (
      <div className="component resource-byline">
        <div className="resource-byline-author">{author}</div>
        <div className="resource-byline-author-title">{authorTitle}</div>
      </div>
    );
  }
}

export default ResourceByline;
