import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../core/Link';
import ResourceByline from '../ResourceByline';

import './index.css';

export class FeaturedResource extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorCompany: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    videoLength: PropTypes.string.isRequired,
    thumbnailImage: PropTypes.string.isRequired,
  };

  render() {
    const {
      path,
      title,
      date,
      author,
      authorTitle,
      authorCompany,
      shortDescription,
      videoLength,
      thumbnailImage,
    } = this.props;

    return (
      <div className="component featured-resource">
        <div className="featured-resource-thumbnail-image">
          <Link to={path}>
            <img src={thumbnailImage} alt="" />
          </Link>
        </div>
        <div className="featured-resource-content">
          <div className="featured-resource-content-label">Featured</div>
          <div className="featured-resource-content-title">
            <Link to={path}>{title}</Link>
          </div>
          <div className="featured-resource-content-short-description">
            {shortDescription}
          </div>
          <div className="featured-resource-content-date">{date}</div>
          <ResourceByline
            author={author}
            authorTitle={authorTitle}
            authorCompany={authorCompany}
          />
          <div className="featured-resource-content-video-length">
            {videoLength}
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedResource;
