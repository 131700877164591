import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import FeaturedResource from '../components/FeaturedResource';
import ResourcesGrid from '../components/ResourcesGrid';

import './index.css';

export class WebinarsPage extends Component {
  static propTypes = {
    webinars: PropTypes.array.isRequired,
  };

  render() {
    const { webinars } = this.props;

    const firstWebinar = webinars[0];

    return (
      <App>
        <div className="component webinars-page">
          <Helmet title="Webinars – ThinkReservations">
            <meta
              name="description"
              content="Register for an upcoming innovative educational webinar or view one from our archive. We're here to help you succeed!"
            />
          </Helmet>

          <FeaturedResource
            path={firstWebinar.path}
            title={firstWebinar.title}
            date={firstWebinar.date}
            author={firstWebinar.author}
            authorTitle={firstWebinar.authorTitle}
            authorCompany={firstWebinar.authorCompany}
            shortDescription={firstWebinar.shortDescription}
            videoLength={firstWebinar.videoLength}
            thumbnailImage={firstWebinar.thumbnailImage}
          />

          <ResourcesGrid resources={webinars} title="Recent webinars" />
        </div>
      </App>
    );
  }
}

export default WebinarsPage;
