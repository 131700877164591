import React from 'react';

import WebinarsPage from '../../components/pages/resources/WebinarsPage';

export default (props) => {
  const { pageContext } = props;

  const { resources } = pageContext;

  return <WebinarsPage webinars={resources} />;
};
