import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../core/Link';
import ResourceByline from '../ResourceByline';

import './index.css';

export class ResourceGridElement extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorCompany: PropTypes.string.isRequired,
    videoLength: PropTypes.string.isRequired,
    thumbnailImage: PropTypes.string.isRequired,
  };

  render() {
    const {
      path,
      title,
      date,
      author,
      authorTitle,
      authorCompany,
      videoLength,
      thumbnailImage,
    } = this.props;

    return (
      <div className="component resource-grid-element">
        <div className="resource-grid-element-image">
          <Link to={path}>
            <img className="thumbnail-image" src={thumbnailImage} alt="" />
          </Link>
        </div>
        <div className="resource-grid-element-content">
          <div className="resource-grid-element-date-and-length">
            <div className="resource-grid-element-date">{date}</div>
            {videoLength && videoLength.length && (
              <div className="resource-grid-element-length">{videoLength}</div>
            )}
          </div>
          <div className="resource-grid-element-title">
            <Link to={path}>{title}</Link>
          </div>
          <ResourceByline
            author={author}
            authorTitle={authorTitle}
            authorCompany={authorCompany}
          />
        </div>
      </div>
    );
  }
}

export default ResourceGridElement;
